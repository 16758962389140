import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}


class footer extends Component {


	render() {
		var currentYear = new Date().getFullYear();
		return (
			<section id="footer">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-3"> © { currentYear } daglavka.com </div>
						<div className="col-md-6">
							<ul>
								<li>
									<a onClick={ () => this.props.openStaticModal('POLICY') }>
										Политика конфиденциальности
									</a>
								</li>
								<li>
									<a onClick={ () => this.props.openStaticModal('AGREEMENT') }>
										Пользовательское соглашение
									</a>
								</li>
								<li>
									<a href="mailto: info@daglavka.com">
										info@daglavka.com
									</a>
								</li>
								<li>
									<a href="tel: +7 968 987-05-05">
										+7 968 987-05-05
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3">
							<div className="social-networks">
								{/*
							<a rel="nofollow" href="https://www.facebook.com/thechudu" target="_blank">
								<i className="fab fa-facebook-f"></i>
							</a>
							<a rel="nofollow" href="https://www.instagram.com/daglavka/" target="_blank">
								<i className="fab fa-instagram"></i>
							</a>
							*/}
							</div>
						</div>
						<div className="col-12 payments_logo">
							<img src="/system_images/payments.png" />
						</div>
					</div>
				</div>
			</section>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(footer)
