import React, { Component } from 'react';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CatPreview from './../../APP/COMPONENTS/ContentParts/CatPreview'
import Adverts from './../../APP/COMPONENTS/ContentParts/Adverts'

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state, myProps) {
    return {
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PRODUCTS: props.PRODUCTS,
            SHOW_ABOUT_TEXT: false
        }
    }

    componentDidMount() {
        this.props.onError(false);
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
        if (this.props.PRODUCTS !== prevProps.PRODUCTS) {
            this.setState({ PRODUCTS: true });
        }
    }

    checkUpdates() {
        if (this.state.PRODUCTS !== this.props.PRODUCTS) {
            if (!this.state.PRODUCTS) {
                this.setState({ UPDATED: true });
            }
        }
    }

    renderAdvert() {
        return <Adverts />;
    }

    renderLinks = () => {
        if (window.innerWidth > 1200) {
            return (
                <div>
                    <Link
                        className="homepage_link_text"
                        title="Наши лавки"
                        to="/restaurants"
                    >
                        Наши лавки
                    </Link>
                    <Link
                        className="homepage_link_text"
                        title="Контакты"
                        to="/company/contacts"
                    >
                        Контакты
                    </Link>
                </div>
            )
        } else return false
    }
    renderAboutNav = () => {
        if (window.innerWidth > 1000) {
            return (
                <div className="about_navs">
                    <a onClick={ () => this.props.openStaticModal('ABOUT') }>О нас</a>
                    <a onClick={ () => this.props.openStaticModal('PAYMENT') }>Оплата</a>
                    <a onClick={ () => this.props.openStaticModal('DELIVERY') }>Доставка</a>
                    { this.renderLinks() }
                </div>
            );
        }

    }

    showArrow = () => {
        if (this.state.SHOW_ABOUT_TEXT) {
            return "/system_images/up_arrow.png"
        } else {
            return "/system_images/down_arrow.png"
        }
    }

    showParticipationText = () => {
        return (
            <>
                <div className="gastromap_text">
                    Дагестанская лавка — участник проекта “Гастрономическая карта России”
                </div>
                <img src="/system_images/gastromap.png" className="gastromap_logo" />
            </>
        )
    }

    showAboutText = () => {
        if (this.state.SHOW_ABOUT_TEXT) {
            return (
                <div className="about_text">
                    <p>
                        Кухня народов Северного Кавказа таит в себе множество загадок, которые уходят корнями в далекое
                        прошлое. Бережно сохраняя традиции прошлых поколений, мы с радостью раскроем вам вкус настоящего
                        Дагестана.
                    </p>
                    <p>
                        В Дагестанской лавке вы сможете насладиться изысканными блюдами кулинарного наследия народов
                        Cеверного Кавказа, приготовленными из натуральных продуктов халяль.
                    </p>
                    <p>
                        Наши хиты - это тонкое чуду, пироги на тонком тесте: с мясом, сыром, зеленью, тыквой и картошкой
                        с грибами. Есть ещё большие дагестанские пироги с мясом и картофелем, творогом и зеленью, сыром,
                        курицей и грибами. В меню также говяжий бульон и разнообразные виды настоящего хинкала (лакский,
                        даргинский, аварский, лезгинский, кумыкский). И конечно у нас есть дагестанские национальные
                        пельмени - курзе: с мясом, творогом или зеленью. Визитная карточка нашей лавки - урбеч из семян
                        льна и абрикосовых косточек.
                    </p>
                    { this.showParticipationText() }
                </div>
            )
        }
    }

    mobileVersionAbout = () => {
        if (window.innerWidth < 1000) {
            return (
                <div>
                    <div className="open_about_title"
                        onClick={ () => this.setState({ SHOW_ABOUT_TEXT: !this.state.SHOW_ABOUT_TEXT }) }>
                        <h1 className="main_h1">Попробуй Дагестан на вкус</h1>
                        <img className="up_down_arrows" src={ this.showArrow() } />
                    </div>
                    { this.showAboutText() }
                </div>
            )
        } else {
            return (
                <div>
                    <div className="open_about_title">
                        <h1 className="main_h1">Попробуй Дагестан на вкус</h1>
                    </div>
                    <div className="about_text">
                        <p>
                            Кухня народов Северного Кавказа таит в себе множество загадок, которые уходят корнями в
                            далекое прошлое. Бережно сохраняя традиции прошлых поколений, мы с радостью раскроем вам
                            вкус настоящего Дагестана.
                        </p>
                        <p>
                            В Дагестанской лавке вы сможете насладиться изысканными блюдами кулинарного наследия народов
                            Cеверного Кавказа, приготовленными из натуральных продуктов халяль.
                        </p>
                        <p>
                            Наши хиты - это тонкое чуду, пироги на тонком тесте: с мясом, сыром, зеленью, тыквой и
                            картошкой с грибами. Есть ещё большие дагестанские пироги с мясом и картофелем, творогом и
                            зеленью, сыром, курицей и грибами. В меню также говяжий бульон и разнообразные виды
                            настоящего хинкала (лакский, даргинский, аварский, лезгинский, кумыкский). И конечно у нас
                            есть дагестанские национальные пельмени - курзе: с мясом, творогом или зеленью. Визитная
                            карточка нашей лавки - урбеч из семян льна и абрикосовых косточек.
                        </p>
                        { this.showParticipationText() }
                    </div>
                </div>
            )
        }
    }

    render() {
        if (!this.state.PRODUCTS) {
            return false;
        }

        return (
            <CSSTransitionGroup
                transitionAppear={ true }
                transitionAppearTimeout={ 600 }
                transitionEnterTimeout={ 600 }
                transitionLeaveTimeout={ 200 }
                transitionName={ 'SlideIn' }
            >
                <Helmet>
                    <title>Дагестанская кухня с доставкой</title>
                    <meta name="description" content="Дагестанская кухня с доставкой" />
                    <meta name="keywords" content="Дагестанская кухня с доставкой" />
                </Helmet>
                <div className="right-part transparent homepage">
                    <section id="main-page-content">
                        <div className="container-fluid">
                            { this.renderAdvert() }
                        </div>
                        {/* <div className="col-sm-12 col-lg-7">
                                {this.mobileVersionAbout()}
                                </div> */}
                    </section>

                    {/*{this.renderAboutNav()}*/ }


                    <CatPreview />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                { this.mobileVersionAbout() }
                            </div>
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
                <FooterBlock />
            </CSSTransitionGroup>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage))
